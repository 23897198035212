import React, { useState, useCallback, useEffect } from 'react'
import { Link } from 'gatsby'
import { 
  Box, 
  Flex, 
  Heading, 
  Text, 
  IconButton,
  Input,
  Image,
  Grid,
  useColorModeValue 
} from '@chakra-ui/react'
import { debounce, groupBy } from 'lodash'
import { v4 as uuidv4 } from 'uuid'

import { FiX } from '@react-icons/all-files/fi/FiX'
import { FiSearch } from '@react-icons/all-files/fi/FiSearch'

export default function Search() {
  const [searchKeyword, setSearchKeyword] = useState('')
  const [searchResult, setSearchResult] = useState({edition: [], post: []})

  const bg = useColorModeValue('white', 'black');

  var fetchData = debounce(function (e) {
    fetch(`${process.env.GATSBY_REST_URL}/search?term=${e? e.target.value : ''}`)
      .then(response => response.json())
      .then(data => {
        setSearchResult(groupBy(data, 'type'));
      });
  }, 1000);

  const handleFetchData = e => {
    setSearchKeyword(e.target.value)
    fetchData(e)
  }

  const handleSearchKeyword = useCallback(
    handleFetchData, []
  )

  const SearchContent = (result, type) => (
    <>
      <Box 
        h="0"
        w="100%"
        pb="100%"
        backgroundImage={result.image}
        backgroundSize="cover"/>
      <Heading mt="5" as="h3" size="md">{result.title}</Heading>
      <Flex justifyContent="space-between">
        <Text fontSize="small" color="gray.500">{result.edition_number}</Text>
        {type === 'edition'? 
          <Link to={`/${result.slug}/cover`}>
            <Text fontSize="small">Read Edition</Text>
          </Link>
        : 
          <Link to={`/${result.edition_slug}/${result.slug}`}>
            <Text fontSize="small">Read Story</Text>
          </Link>
        }
      </Flex>
    </>
  )

  useEffect(() => {
    fetchData()
  }, [])

  return (
    <Box
      position="absolute"
      bottom="0"
      top={[0, '-58px']}
      left={['0', '80px', '80px']}
      right="0"
      height={['100%', '100vh']}
      bg={bg}
      pb="16"
      overflowY="auto"
      zIndex={10}
    >
      <Box 
        pl="12" 
        pr="20" 
        pt={['10', '10', '10']}
      >
        <Flex alignItems="center" borderBottom="1px solid">
          <FiSearch fontSize="26" />
          <Input 
            border="0"
            boxShadow="none !important"
            flex="1" 
            placeholder="Start your search here" 
            size="lg" 
            value={searchKeyword}
            onChange={handleSearchKeyword}
            fontSize="26"
          />
          <IconButton 
            boxShadow="none !important"
            icon={<FiX fontSize="26" />} 
            variant="ghost"
            onClick={() => setSearchKeyword('')}
          />
        </Flex>
        {searchResult.post?.length > 0 && (
          <>
            <Text mt="16" fontSize="sm" fontWeight="bold">Stories</Text>
            <Grid
              templateColumns={['repeat(1, 1fr)', 'repeat(2, 1fr)', 'repeat(3, 1fr)', 'repeat(4, 1fr)']}
              columnGap={10}
              rowGap={14}
              mt="12"
            >
              {searchResult.post?.map((result) => 
                <Box key={uuidv4()} w="100%">
                  {SearchContent(result, 'post')}
                </Box>
              )}
            </Grid>
          </>
        )}
        {searchResult.edition?.length > 0 && (
          <>
            <Text mt="16" fontSize="sm" fontWeight="bold">Editions</Text>
            <Grid
              templateColumns={['repeat(1, 1fr)', 'repeat(2, 1fr)', 'repeat(3, 1fr)', 'repeat(4, 1fr)']}
              columnGap={10}
              rowGap={14}
              mt="12"
            >
              {searchResult.edition?.map((result) => 
                <Box key={uuidv4()} w="100%">
                  {SearchContent(result, 'edition')}
                </Box>
              )}
            </Grid>
          </>
        )}
      </Box>
    </Box>
  )
}
