import React from 'react'
import { NavbarStyle } from '../../utilities/styles'
import { Link } from 'gatsby'
import { StaticImage } from "gatsby-plugin-image"
import { Box, Divider, IconButton, Spacer, VStack } from '@chakra-ui/react'
import { FiMoon } from '@react-icons/all-files/fi/FiMoon'
import { FiSun } from '@react-icons/all-files/fi/FiSun'
import { FiSearch } from '@react-icons/all-files/fi/FiSearch'
import { FiMenu } from '@react-icons/all-files/fi/FiMenu'
import { FiX } from '@react-icons/all-files/fi/FiX'

export default function Nav({ 
  openMenu, 
  handleOpenMenu, 
  handleOpenSearch,
  colorMode, 
  toggleColorMode, 
}) {

  return (
    <VStack 
      position="fixed"
      w="80px" 
      left="0"
      top="0"
      bottom="0"
      pt="2"
      bg={NavbarStyle.Bg()}
      height="100%"
      borderRight="1px" 
      display={['none', , 'flex']}
    >
      <Box p="2">
        <Link to="/">
          {
            colorMode === 'light' 
            ? <StaticImage src="../../images/jomweb-small-white.png" alt="Jomweb" /> 
            : <StaticImage src="../../images/jomweb-small-black.png" alt="Jomweb" />
          }
        </Link>
      </Box>
      <Divider />
      { openMenu 
        ? (
          <IconButton 
            icon={<FiX fontSize="26" />} 
            variant="ghost"
            onClick={() => handleOpenMenu(false)}
          />
        )
        : (
          <IconButton 
            icon={<FiMenu fontSize="26" />} 
            variant="ghost"
            onClick={() => handleOpenMenu(true)}
          />
        ) 
      }
      <IconButton 
        icon={<FiSearch fontSize="26" />} 
        variant="ghost"
        onClick={() => handleOpenSearch()}
      />
      <IconButton
        onClick={toggleColorMode} 
        icon={
          colorMode === "light" 
          ? <FiMoon fontSize="26" />
          : <FiSun fontSize="26" />
        } 
        variant="ghost"
      />
      <Spacer/>
    </VStack>
  )
}
