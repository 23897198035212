import { createBreakpoints } from "@chakra-ui/theme-tools"

export const Breakpoints = createBreakpoints({
  sm: "577px",
  md: "768px",
  lg: "1025px",
  xl: "1337px",
  // sm: "30em",
  // md: "48em",
  // lg: "62em",
  // xl: "80em",
  "2xl": "96em",
})

export const ThemeConfig = {
  initialColorMode: "dark",
  useSystemColorMode: false,
}

export const ThemeFont = {
  fonts: {
    heading: "Open Sauce Sans",
    body: "Open Sauce Sans"
  }
}

export const ThemeGlobal = {
  global: {
    h1: {
      display: 'block',
      fontSize: '2em',
      marginTop: '0.67em',
      marginBottom: '0.67em',
      marginLeft: 0,
      marginRight: 0,
      fontWeight: 'bold'
    },
    h2: {
      display: 'block',
      fontSize: '1.5em',
      marginTop: '0.83em',
      marginBottom: '0.83em',
      marginLeft: 0,
      marginRight: 0,
      fontWeight: 'bold'
    },
    h3: {
      display: 'block',
      fontSize: '1.17em',
      marginTop: '1em',
      marginBottom: '1em',
      marginLeft: 0,
      marginRight: 0,
      fontWeight: 'bold'
    },
    h4: {
      display: 'block',
      marginTop: '1.33em',
      marginBottom: '1.33em',
      marginLeft: 0,
      marginRight: 0,
      fontWeight: 'bold'
    },
    h5: {
      display: 'block',
      fontSize: '0.83em',
      marginTop: '1.67em',
      marginBottom: '1.67em',
      marginLeft: 0,
      marginRight: 0,
      fontWeight: 'bold'
    },
    h6: {
      display: 'block',
      fontSize: '0.67em',
      marginTop: '2.33em',
      marginBottom: '2.33em',
      marginLeft: 0,
      marginRight: 0,
      fontWeight: 'bold'
    }
  }
}