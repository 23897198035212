import React from 'react'
import { StaticQuery, graphql, Link as GatsbyLink } from "gatsby"
import { 
  Box, 
  Flex, 
  Heading, 
  IconButton,
  Text, 
  Link,
  List, 
  ListItem, 
  useColorModeValue 
} from '@chakra-ui/react'
import { FiSearch } from '@react-icons/all-files/fi/FiSearch'
import { FiMoon } from '@react-icons/all-files/fi/FiMoon'
import { FiSun } from '@react-icons/all-files/fi/FiSun'

const MyMenu = ({ data, colorMode, toggleColorMode, handleOpenSearch }) => {
  const dataLinks = data?.allWpMenuItem?.nodes || []
  const socials = data?.allWpPage?.nodes?.aboutUsAdditionalFields?.socialMedia || []
  const bg = useColorModeValue('white', 'black')
  const tempLinks = [
    {id: 1, path: '/', label: 'Homepage'},
    {id: 2, path: '/editions/', label: 'Editions'},
    {id: 3, path: '/about/', label: 'About Us'},
    {id: 4, path: '/get-involved/', label: 'Get Involved'},
  ]
  const links = dataLinks.length > 0 ? dataLinks: tempLinks;

  return (
    <Box
      position="absolute"
      bottom="0"
      top={['0', '-58px']}
      left={['0', '80px', '80px']}
      right="0"
      height="110%"
      bg={bg}
      display={['block', 'block', 'block', 'flex']}
      overflowY={['scroll', 'scroll', 'scroll', 'hidden']}
      zIndex={10}
    >
      <Box 
        pl="12" 
        pr="20" 
        pt={['10', '10', '24']}
        w={['100%', '100%', '100%', '60%']}
      >
        <Flex display={['flex', 'none']} mb="8">
          <IconButton 
            icon={<FiSearch fontSize="26" />} 
            variant="ghost"
            onClick={handleOpenSearch}/>
          <IconButton
            mr="4"
            onClick={toggleColorMode} 
            variant="ghost"
            icon={
              colorMode === "light" 
              ? <FiMoon fontSize="26" />
              : <FiSun fontSize="26" />
            }/>
        </Flex>
        <Text fontSize="md">Menu</Text>
        <List mt="6">
          {links.map((link) => 
            <ListItem key={link.id}>
              <Heading as="h1" size="3xl" mb="6">
                <GatsbyLink to={link.path}>{link.label}</GatsbyLink>
              </Heading>
            </ListItem>
          )}
        </List>
      </Box>
      <Box 
        pl="12" 
        pr="20" 
        pt="10"
        pb={['24', '24', '10', '0']}
        w={['100%', '100%', '100%', '40%']} 
        borderLeft={['none', 'none', 'none', '1px']}
      >
        <Text fontSize="md" mb="8">Contact</Text>
        <Text fontSize="2xl" mb="6">Follow our Socials!</Text>
        <Flex mb="10">
          {socials.map(link => 
            <Link href={link.url} target="_blank" mr="4"><Text fontSize="xs">{link.label}</Text></Link>
          )}
        </Flex>
      </Box>
    </Box>
  )
}

export default function Menu({colorMode, toggleColorMode, handleOpenSearch}) {
  return (
    <StaticQuery
      query={graphql`
        query {
          allWpMenuItem {
            nodes {
              url
              path
              label
              id
            }
          }
          allWpPage(filter: {slug: {eq: "about"}}) {
            nodes {
              aboutUsAdditionalFields {
                socialMedia {
                  label
                  url
                }
              }
            }
          }
        }
      `}
      render={data => (
        <MyMenu 
          data={data} 
          colorMode={colorMode} 
          toggleColorMode={toggleColorMode} 
          handleOpenSearch={handleOpenSearch} />
      )}
    />  
  )
}
