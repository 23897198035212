import React, { useState, useEffect } from 'react'
import { globalHistory } from '@reach/router'
import { Box, useColorMode, Slide, Portal } from '@chakra-ui/react'

import VerticalNav from './VerticalNav'
import HorizontalNav from './HorizontalNav'
import Menu from '../Menu'
import Search from '../Search'

export default function Nav() {
  const { colorMode, toggleColorMode } = useColorMode()

  const [openMenu, setOpenMenu] = useState(false)
  const [openSearch, setOpenSearch] = useState(false)
  
  const handleOpenMenu = (open) => {
    if (!open) {
      setOpenMenu(false)
      setOpenSearch(false)
    } else {
      setOpenMenu(true)
      setOpenSearch(false)
    }
  }
  
  const handleOpenSearch = () => {
    setOpenSearch(!openSearch)
    openSearch && setOpenMenu(false)
  }

  // hide menu when detect route change
  useEffect(() => {
    return globalHistory.listen(({ action }) => {
      if (action === 'PUSH') {
        setOpenMenu(false)
        setOpenSearch(false)
      }
    })
  }, [setOpenMenu, setOpenSearch])

  return (
    <Box height={['unset', '100%']} zIndex="1">
      <HorizontalNav 
        colorMode={colorMode} 
        toggleColorMode={toggleColorMode}
        openMenu={openMenu}
        handleOpenMenu={handleOpenMenu}
        openSearch={openSearch}
        handleOpenSearch={handleOpenSearch}
      />

      <Portal>
        <Slide 
          direction="left" 
          in={openMenu}
          style={{
            top: '58px'
          }}>
          <Menu 
            colorMode={colorMode} 
            toggleColorMode={toggleColorMode}
            handleOpenSearch={handleOpenSearch}/>
        </Slide>

        
        <Slide 
          direction="left" 
          in={openSearch}
          style={{
            top: '58px'
          }}>
          <Search />
        </Slide>

        <VerticalNav 
          colorMode={colorMode} 
          toggleColorMode={toggleColorMode} 
          openMenu={openMenu}
          handleOpenMenu={handleOpenMenu}
          openSearch={openSearch}
          handleOpenSearch={handleOpenSearch}/>
      </Portal>
    </Box>
  )
}
