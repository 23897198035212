import React from 'react'
import { 
	Box, 
	Flex, 
	ChakraProvider, 
	ColorModeScript, 
	extendTheme, 
	useColorModeValue 
} from '@chakra-ui/react'
import Nav from './navbar'
import Fonts from '../utilities/fonts'
import { ThemeConfig, ThemeGlobal, ThemeFont } from '../utilities/theme'
import "@wordpress/block-library/build-style/style.css"

const theme = extendTheme({ 
	styles: ThemeGlobal,
	config: ThemeConfig, 
	fonts: ThemeFont
})

export default function Layout({ children }) {
	const bg = useColorModeValue("black.800", "black.100")

	return (
		<ChakraProvider theme={theme}>
			<Fonts />
			<ColorModeScript initialColorMode={theme.config.initialColorMode} />
			<Box 
				bg={bg}
				position="fixed" 
				top="0" 
				bottom="0" 
				left="0" 
				right="0"
				>
				<Flex 
					id="container"
					direction={['column', 'row']} 
					height="100%" 
					overflowY="auto"
					style={{
						scrollBehavior: "smooth"
					}}>
					<Nav />
					<Box 
						flex="1" 
						ml={[0, '80px']}>
						{children}
					</Box>
				</Flex>
			</Box>
		</ChakraProvider>
	)
}
