import { useColorModeValue } from "@chakra-ui/color-mode"

// useColorModeValue(lightOption, darkOption)

export const NavbarStyle = {
  Bg: () => {return useColorModeValue('gray.50', 'gray.900')}
}

export const HomeStyle = {
  ContentBox: {
    overflow: 'hidden',
    display: '-webkit-box',
    WebkitLineClamp: '3',
    WebkitBoxOrient: 'vertical'
  },
  ArrowIcon: '#f0a966',
}

export const EditionCoverStyle = {
  ArrowButton: {
    border: '2px solid #f0a966', 
    borderRadius: '50%',
    padding: '15px',
  },
  ArrowIcon: '#f0a966',
  ViewChapterButtonContainerBg: () => {return useColorModeValue('gray.50', 'gray.900')}
}

export const EditionStyle = {
  Bg: () => {return useColorModeValue('gray.100', 'gray.900')},
  HeaderBg: () => {return useColorModeValue('gray.50', 'gray.700')},
  ChapterListBg: () => {return useColorModeValue('gray.200', 'gray.800')},
  EditionText: () => {return useColorModeValue('gray.600', 'gray.400')},
  ChapterText: () => {return useColorModeValue('gray.900', 'gray.50')},
  PageText: () => {return useColorModeValue('gray.900', 'gray.50')},
  ArrowIcon: () => {return useColorModeValue('gray.900', 'gray.50')},
  ButtonBg: () => {return useColorModeValue('gray.800', 'gray.600')},
  ButtonText: () => {return useColorModeValue('gray.50', 'gray.50')},
  TitleText: () => {return useColorModeValue('gray.900', 'gray.50')},
  DescriptionText: () => {return useColorModeValue('gray.700', 'gray.300')},
}

export const PostStyle = {
  Bg: () => {return useColorModeValue('gray.100', 'gray.900')},
  TitleBg: () => {return useColorModeValue('gray.50', 'gray.800')},
  TocBorder: () => {return useColorModeValue('gray.100', 'gray.700')},
  PublisherNameText: () => {return useColorModeValue('#dd6b20', '#f6ad55')},
  PublishedTimeText: () => {return useColorModeValue('gray.600', 'gray.300')},
  ReadingBarBg: () => {return useColorModeValue('gray.50', 'gray.700')},
  ReadingBarText: () => useColorModeValue('gray.600', 'gray.500'),
  ReadingBarButton: () => useColorModeValue('gray.100', 'gray.600'),
  ReadingBarButtonBorder: () => useColorModeValue('gray.100', 'gray.600'),
}

export const CommonStyle = {
  fullsizeButton: {
    display: 'flex',
    height: '100%',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center'
  },
  roundedButton: {
    border: '2px solid #f0a966', 
    borderRadius: '50%',
    padding: '15px',
  },
  PopupBg: () => {return useColorModeValue('gray.200', 'gray.800')},
}

export const ScreenBreakpoints = {
  mobile: 480,
  tablet: 768,
  desktop: 1024
}